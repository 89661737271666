import dazon_img_1 from "../../images/Blind-angels-dazon-profile.png";
import dazon_img_1_mobile from "../../images/Blind-angels-dazon-profile_mobile.png";

import dazon_img_2 from "../../images/Blind-angels-dazon-woman-holding-hands.png";
import dazon_img_2_mobile from "../../images/Blind-angels-dazon-woman-holding-hands.png";

const fullName = "DÁZON DIXON DIALLO";

const copyListDazon = [
  {
    type: "text",
    content:
      "Dázon Dixon Diallo, the founder and president of SisterLove, Inc,* is a pioneering advocate for women living with and at risk for HIV. She started her Atlanta, Georgia–based organization in 1989, at a time when it was often believed that HIV impacted primarily men.<sup>1</sup> Its original mission was to provide HIV prevention education to women of color in the community. Over the last three decades, SisterLove, Inc has expanded to address a broad range of needs related to HIV in communities of color, and the organization has become a strong voice for sexual health and reproductive justice for Black women and members of other marginalized communities.",
  },
  {
    type: "text",
    content:
      "According to United States data from 2017 to 2019, the likelihood of being diagnosed with HIV over the course of a lifetime is significantly higher for Black women (1 in 75) than Hispanic/Latina women (1 in 287) or White women (1 in 874).<sup>2</sup> Furthermore, while new HIV diagnoses among Black women dropped by 10% in the US between 2014 and 2018, Black women still accounted for 58% of new HIV diagnoses among women in 2018 even though they accounted for only 13% of the female population.<sup>3,4</sup> In the South, the disparity is even greater, with Black women accounting for 67% of new HIV diagnoses among all women in the region in 2017.<sup>5</sup>",
  },
  {
    type: "image",
    content: {
      src: dazon_img_1,
      mobileSrc: dazon_img_1_mobile,
      alt: "Dazon talking to woman",
      caption: "Dázon Dixon Diallo",
    },
  },
  {
    type: "text",
    content:
      "Black women in the US face a number of factors, including poverty, racism, and gender discrimination, that can increase their risk for HIV.<sup>6</sup> For example, Black women are more likely than other women to live in neighborhoods with relatively high HIV prevalence, which increases the likelihood of having a sexual partner with HIV.<sup>7</sup> Black women are also more likely than White women to be diagnosed with other sexually transmitted infections (STIs), such as syphilis and gonorrhea, which increases their HIV risk.<sup>8,9</sup>",
  },
  {
    type: "text",
    content:
      "Black Americans may not perceive they are at risk for HIV or in need of prevention methods. For example, a 2016 survey of 855 Black men and women who identified as HIV negative—the majority of whom resided in the South—found that only about 15% had heard of pre-exposure prophylaxis (PrEP) medicine, an HIV prevention strategy that involves using a prescription medicine to reduce the risk for new HIV infection, and only 26% were willing to take it. Black men who have sex with men (MSM) were more likely to have heard of or to be willing to take PrEP medicine, compared to the overall survey population. When asked why they were reluctant, 73% of respondents said: “I’m not at risk for HIV infection.”<sup>10</sup>",
  },
  {
    type: "text",
    content:
      "In 2012, researchers conducted focus groups with a small number of Black women in the South, as well as healthcare and social service providers. What they heard was that Black women living in poverty may not seek HIV prevention or treatment because they have to focus on meeting basic needs (like food, housing, and family demands), lack health insurance, face access challenges (such as lack of transportation or a phone), or may be exposed to risky environments (like sex work or drug use).<sup>11</sup> Moreover, the history of race-based abuse against Black women, including experiences of abuse by the medical establishment, may leave Black women feeling both distrustful and disempowered when it comes to their sexual healthcare.<sup>7,12</sup>",
  },
  {
    type: "blockquote",
    content:
      "In 2018, Black American women accounted for more than half (58%) of new HIV diagnoses among women, while only accounting for 13% of the female population in the US that year.<sup>2</sup>",
  },
  {
    type: "text",
    content:
      "Most new HIV diagnoses among Black women in the US were attributed to heterosexual contact as of 2018,<sup>4</sup> so organizations like SisterLove, Inc often focus on increasing condom use.<sup>13</sup> According to a 2011-2015 survey, 22.1% of Black women reported always using a condom compared to 20.5% of Hispanic/Latina and 17.2% of White women.<sup>14</sup> A small study from 2013 showed that young Black women put the needs of others ahead of their own needs in order to avoid conflict in relationships; those who do this are less likely to use condoms to prevent HIV and other STIs.<sup>15</sup> In contrast, Black women who are more confident in and less fearful of their ability to negotiate condom use with their partners and who have had sexual health conversations with their partners are more likely to use condoms.<sup>16</sup>",
  },
  {
    type: "text",
    content:
      "SisterLove, Inc’s Healthy Love Workshop (HLW) aims to improve condom use by offering a targeted safer sex intervention for Black women that helps participants assess their own risk for HIV and reminds them that safer sex can be good sex. The program, which strives to be fun and sex positive, includes condom use skill-building activities and teaches condom negotiation techniques.<sup>13,17</sup> A rigorous evaluation of the program conducted by SisterLove, Inc in Atlanta from 2006 to 2007 and approved by the Centers for Disease Control and Prevention (CDC) found the HLW to be effective in increasing both condom use and HIV testing among participants.<sup>13</sup> Other successful programs have focused on raising awareness about HIV/STI risks, improving condom use skills, increasing sexual negotiation skills, setting risk reduction goals, and increasing social support and linkage to services.<sup>18</sup>",
  },
  {
    type: "text",
    content:
      "There is also a growing understanding that, to be effective in empowering Black women, programs must acknowledge the intersection of poverty, race, and health, as well as the history of sexual and reproductive abuse faced by generations of Black women.<sup>7,12</sup> Culturally competent programs have the potential to help Black women protect their sexual health and reduce their risk for HIV.<sup>13,18</sup>",
  },
  {
    type: "image",
    content: {
      src: dazon_img_2,
      mobileSrc: dazon_img_2_mobile,
      alt: "Dazon holding the hand of a woman as they walk",
      caption: "",
    },
  },
];

export default copyListDazon;
