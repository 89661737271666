import * as React from "react";
import { Component } from 'react';
import Layout from '../../components/global/layout'
import { Content } from '../../components/dazon/content'


class Dazon extends Component {
  render () {
  return (
    <>
      <Layout section="articles" page="dazon">
        <Content section="articles" page="dazon" />
      </Layout>
    </>
  )
  }
}

export default Dazon;